import { Component } from 'react';
import { makeStyles } from '@mui/styles';
import Logger from 'utils/Logger';

const log = new Logger('ErrorBoundary');

interface State {
  error?: Error;
  errorInfo?: React.ErrorInfo;
}

const useStyles = makeStyles(() => ({
  errorContainer: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100vh',
  },
  errorTitle: {
    marginTop: '30px',
    fontSize: '24px',
  },
  errorMessage: {
    marginTop: '8px',
    textAlign: 'center',
  },
  sadFace: {
    backgroundImage: `url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='44' height='44' viewBox='0 0 44 44'%3E%3Cg fill='none' fill-rule='evenodd'%3E%3Ccircle cx='22' cy='22' r='22' fill='%23C7F0F5'/%3E%3Cg%3E%3Cpath d='M0 0H22V14H0z' transform='translate(11 17)'/%3E%3Cpath fill='%230E203E' d='M11 8.5c-3.119 0-5.696 2.291-6.159 5.28H17.16C16.696 10.791 14.119 8.5 11 8.5zm8.75-2c-1.243 0-2.25-1.007-2.25-2.25S18.507 2 19.75 2 22 3.007 22 4.25 20.993 6.5 19.75 6.5zm-17.5 0C1.007 6.5 0 5.493 0 4.25S1.007 2 2.25 2 4.5 3.007 4.5 4.25 3.493 6.5 2.25 6.5z' transform='translate(11 17)'/%3E%3C/g%3E%3C/g%3E%3C/svg%3E%0A")`,
    height: '44px',
    width: '44px',
  },
}));

function ErrorComponent({ error }: { error?: Error; info?: React.ErrorInfo }) {
  const classes = useStyles();

  return (
    <div className={classes.errorContainer}>
      <div className={classes.sadFace}></div>
      <div className={classes.errorTitle}>Uh Oh SpaghettiOs</div>
      <div className={classes.errorMessage}>
        {error?.toString()}
        {!error && 'Something went wrong'}
      </div>
    </div>
  );
}

class ErrorBoundary extends Component<{}, State> {
  state: State = {
    error: undefined,
    errorInfo: undefined,
  };

  static getDerivedStateFromError(error: Error) {
    log.debug('getDerivedStateFromError() error', error);
    return { hasError: true };
  }

  componentDidCatch(error: Error, errorInfo: React.ErrorInfo): void {
    log.debug('componentDidCatch() error', error);
    log.debug('componentDidCatch() errorInfo', errorInfo);
    this.setState({
      error,
      errorInfo,
    });
  }

  render(): JSX.Element {
    const { children } = this.props;
    const { error, errorInfo } = this.state;

    if (!errorInfo) {
      return <>{children}</>;
    }

    return <ErrorComponent error={error} info={errorInfo} />;
  }
}

export default ErrorBoundary;
