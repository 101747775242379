import { useState, useEffect } from 'react';
import {
  updateDoc,
  collection,
  onSnapshot,
  doc,
  setDoc,
  deleteDoc,
  query,
  where,
} from 'firebase/firestore';
import { FormGroup, Typography, Button } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';

import Loader from 'components/loader/Loading';
import { useFirebase } from 'providers/FirebaseProvider';
import { useAuth } from 'providers/AuthProvider';
import { MaintenanceMessage } from 'types/Flag.types';
import Message from './Message';
import EditMessageDialog from 'components/Dialog/EditMessageDialog';

interface Props {
  collectionName: string;
}

const EMPTY_MESSAGE: MaintenanceMessage = {
  type: 'message',
  name: '',
  enabled: false,
  regex: '',
  allowPercentage: 0,
  message: '',
  severity: 'info',
};

export function Messages({ collectionName }: Props) {
  const [loading, setLoading] = useState(true);
  const [open, setOpen] = useState(false);
  const [items, setItems] = useState<MaintenanceMessage[]>([]);
  const { isWriter } = useAuth();
  const { store } = useFirebase();
  const [message, setMessage] = useState<MaintenanceMessage | null>(null);

  useEffect(() => {
    setLoading(true);
    const q = query(
      collection(store, collectionName),
      where('type', '==', 'message')
    );
    const unsub = onSnapshot(q, (querySnapshot) => {
      let docs: MaintenanceMessage[] = [];
      querySnapshot.forEach((doc) => {
        const docData = doc.data() as MaintenanceMessage;
        docs.push(docData);
      });
      setItems(docs);
      setLoading(false);
    });

    return () => unsub();
  }, [store, collectionName]);

  async function updateMessage(event: React.ChangeEvent<HTMLInputElement>) {
    const { name, value } = event.target;
    const flagRef = doc(store, collectionName, name);
    const boolVal = value === 'true';
    try {
      await updateDoc(flagRef, {
        enabled: !boolVal,
        lastModifiedOn: new Date(),
      });
    } catch (err) {
      console.log(err);
    }
  }

  async function createMessage(message: MaintenanceMessage) {
    const messageRef = doc(store, collectionName, message.name);

    try {
      await setDoc(messageRef, {
        ...message,
        created: new Date(),
      });
    } catch (err) {
      console.log(err);
    }
  }

  async function deleteMessage(name: string) {
    const messageRef = doc(store, collectionName, name);

    try {
      await deleteDoc(messageRef);
    } catch (err) {
      console.log(err);
    }
  }

  const handleOpen = (message: MaintenanceMessage) => {
    setMessage(message);
    setOpen(true);
  };

  const handleClose = () => setOpen(false);

  return (
    <>
      {message && (
        <EditMessageDialog
          open={open}
          onClose={handleClose}
          onSave={createMessage}
          message={message}
          deleteMessage={deleteMessage}
        />
      )}

      <Typography variant='h5' sx={{ margin: '1rem 0' }}>
        Messages
      </Typography>
      {loading && (
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <Loader />
        </div>
      )}
      <FormGroup>
        {items.length === 0 && (
          <div style={{ marginBottom: '1rem', textAlign: 'center' }}>
            No messages
          </div>
        )}
        {items.length > 0 && (
          <div style={{ marginBottom: '1rem' }}>
            {items.map((message: MaintenanceMessage) => (
              <Message
                key={message.name}
                message={message}
                updateMessage={updateMessage}
                isWriter={isWriter}
                editMessage={handleOpen}
              />
            ))}
          </div>
        )}
        {isWriter && (
          <Button
            onClick={() => handleOpen(EMPTY_MESSAGE)}
            startIcon={<AddIcon />}
            disabled={!isWriter}
            variant='outlined'
          >
            Add Message
          </Button>
        )}
      </FormGroup>
    </>
  );
}

export default Messages;
