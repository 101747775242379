import React, { createContext, useContext } from 'react';
import { Firestore } from '@firebase/firestore';
import { Auth } from 'firebase/auth';

import { firestore as store, firebaseAuth as auth } from '../utils/firebase';

export interface IFirebaseContext {
  store: Firestore;
  auth: Auth;
}

export const FirebaseContext = createContext<IFirebaseContext>({} as any);

export const useFirebase = () => useContext(FirebaseContext);

export function FirebaseProvider({
  children,
}: React.PropsWithChildren<unknown>) {
  return (
    <FirebaseContext.Provider value={{ store, auth }}>
      {children}
    </FirebaseContext.Provider>
  );
}

export default FirebaseProvider;
