import {
  createContext,
  PropsWithChildren,
  useContext,
  useEffect,
  useState,
} from 'react';
import {
  sendEmailVerification,
  signInWithPopup,
  signOut,
  GoogleAuthProvider,
} from 'firebase/auth';

import { noop } from 'lodash';
import Logger from '../utils/Logger';
import { doc, setDoc, getDoc, onSnapshot } from 'firebase/firestore';
import { useFirebase } from 'providers/FirebaseProvider';
import { FirebaseUserAuth, UserInfo } from 'types/User.types';

const log = new Logger('AuthProvider');

export interface IAuthContext {
  loading: boolean;
  authIsReady: boolean;
  isAuthenticated?: boolean;
  user: FirebaseUserAuth | null;
  login(redirectUri?: string): void;
  logout(): void;
  isAdmin?: boolean;
  isWriter: boolean;
}

export const AuthContext = createContext<IAuthContext>({
  loading: true,
  authIsReady: false,
  isAuthenticated: false,
  logout: noop,
  login: noop,
  user: null,
  isAdmin: false,
  isWriter: false,
});

export const useAuth = () => useContext(AuthContext);

const provider = new GoogleAuthProvider();

function AuthProvider({ children }: PropsWithChildren<{}>) {
  const [authIsReady, setAuthIsReady] = useState(false);
  const [loading, setLoading] = useState(true);
  const [user, setUser] = useState<FirebaseUserAuth | null>(null);
  const [isWriter, setIsWriter] = useState(false);
  const { auth, store } = useFirebase();

  useEffect(() => {
    const unsub = auth.onAuthStateChanged((user) => {
      log.log('onAuthStateChanged User: ', user);
      setAuthIsReady(true);
      setUser(user);
    });

    return () => unsub();
  }, [auth]);

  useEffect(() => {
    if (!user) {
      return;
    }

    const userRef = doc(store, 'users', user.uid);
    const unsub = onSnapshot(userRef, (doc) => {
      const data = doc.data() as UserInfo;

      checkPermissions(data.permissions);
    });

    return () => unsub();
  }, [store, user]);

  function checkPermissions(permissions: string[]) {
    const isWriter: boolean = !!permissions.find((permission) =>
      permission.includes('write:flags')
    );

    setIsWriter(isWriter);
  }

  async function login(redirectUri?: string) {
    setLoading(true);
    log.debug('login() redirectUri', redirectUri);
    try {
      const { user } = await signInWithPopup(auth, provider);
      log.log('login user: ', user);
      const userRef = doc(store, 'users', user.uid);
      const userSnap = await getDoc(userRef);

      if (userSnap.exists()) {
        const { permissions } = userSnap.data() as UserInfo;
        checkPermissions(permissions);
      } else {
        const userInfo: UserInfo = {
          uid: user.uid,
          displayName: user.displayName,
          phoneNumber: user.phoneNumber,
          providerId: 'google',
          email: user.email,
          photoURL: user.photoURL,
          emailVerified: user.emailVerified,
          permissions: [],
        };
        await setDoc(doc(store, 'users', user.uid), userInfo);

        verifyUser(user);
      }
      setUser(user);
    } catch (err) {
      log.error('error ', err);
    } finally {
      setLoading(false);
    }
  }

  async function logout() {
    log.debug('logout()');
    setLoading(true);

    try {
      signOut(auth);
      log.log('logged out succesfully');
      setUser(null);
      setLoading(false);
    } catch (err) {
      log.error('logout error: ', err);
      setLoading(false);
    }
  }

  async function verifyUser(currentUser: FirebaseUserAuth) {
    try {
      await sendEmailVerification(currentUser);

      log.log('Verification sent');
    } catch (err) {
      log.error('error sending verification email', err);
    }
  }

  return (
    <AuthContext.Provider
      value={{
        authIsReady,
        loading,
        isWriter,
        user,
        logout,
        login,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
}

export default AuthProvider;
