import Logger from 'utils/Logger';

const logger = Logger.create('configuration');

const region = process.env.REACT_APP_REGION || 'int';

const configuration = {
  isProduction: process.env.NODE_ENV === 'production',
  isDevelopment: process.env.NODE_ENV === 'development',
  environment: process.env.REACT_APP_ENVIRONMENT || 'local',
  version: process.env.REACT_APP_VERSION || 'local',
  region,
  auth0: {
    domain: process.env.REACT_APP_AUTH0_DOMAIN || '',
    clientId: process.env.REACT_APP_AUTH0_CLIENT_ID || '',
    audience: process.env.REACT_APP_AUTH0_AUDIENCE || '',
    redirectUri: `${window.location.origin}/callback`,
    logoutUri: `${window.location.origin}/logout`,
  },
  api: {
    base: process.env.REACT_APP_API_BASE_URL || '',
  },
  firebase: {
    key: {
      apiKey: process.env.REACT_APP_FIREBASE_API_KEY || '',
      authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN || '',
      projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID || '',
      storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET || '',
      appId: process.env.REACT_APP_FIREBASE_APP_ID || '',
      measurementId: process.env.REACT_APP_FIREBASE_MESAUREMENT_ID || '',
    },
  },
};

logger.log(configuration);

export default configuration;
