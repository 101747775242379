import { useEffect, useState } from 'react';
import {
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from '@mui/material';
import MuiDialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { MaintenanceMessage, Severity } from 'types/Flag.types';

const SEVERITY: Severity[] = ['info', 'warning', 'alert'];

export interface Props {
  open: boolean;
  onClose: () => void;
  onSave: (message: MaintenanceMessage) => void;
  message: MaintenanceMessage;
  deleteMessage: (name: string) => void;
}

function EditMessageDialog({
  deleteMessage,
  onClose,
  open,
  onSave,
  message,
}: Props) {
  const [item, setItem] = useState<MaintenanceMessage>({
    type: 'message',
    name: '',
    enabled: false,
    message: '',
    regex: '',
    allowPercentage: 0,
    severity: 'info',
  });

  const handleClose = () => {
    onClose();
  };

  const handleSave = () => {
    onSave(item);
    handleClose();
  };

  const handleDelete = () => {
    deleteMessage(message.name);
    handleClose();
  };

  useEffect(() => {
    setItem({
      type: 'message',
      name: message.name,
      enabled: message.enabled,
      message: message.message,
      regex: message.regex,
      allowPercentage: message.allowPercentage,
      severity: message.severity,
    });
  }, [message]);

  return (
    <MuiDialog onClose={handleClose} open={open}>
      <DialogTitle>
        {item.name.length > 0 ? `Edit message ${item.name}` : 'Create Message'}
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          Provide the info of the maintenance message
        </DialogContentText>
        {message.name.length === 0 && (
          <div style={{ marginTop: '16px' }}>
            <TextField
              label='Name'
              fullWidth
              value={item.name}
              onChange={(e) => setItem({ ...item, name: e.target.value })}
              error={item.name.length === 0}
              helperText={
                item.name.length === 0 ? 'Please provide a message name' : ''
              }
            />
          </div>
        )}
        <div style={{ marginTop: '16px' }}>
          <TextField
            label='Message'
            fullWidth
            value={item.message}
            onChange={(e) => setItem({ ...item, message: e.target.value })}
            error={item.message.length === 0}
            helperText={
              item.message.length === 0 ? 'Please provide a message text' : ''
            }
          />
        </div>
        <div style={{ marginTop: '16px' }}>
          <TextField
            label='Regex'
            fullWidth
            value={item.regex}
            onChange={(e) => setItem({ ...item, regex: e.target.value })}
          />
        </div>
        <div style={{ marginTop: '16px' }}>
          <TextField
            label='Percentage Allowed'
            fullWidth
            value={item.allowPercentage}
            type='number'
            onChange={(e) =>
              setItem({ ...item, allowPercentage: Number(e.target.value) })
            }
          />
        </div>
        <div style={{ marginTop: '16px' }}>
          <FormControl fullWidth>
            <InputLabel id='severity-label'>Severity</InputLabel>
            <Select
              labelId='severity-label'
              id='severity'
              value={item.severity}
              label='Severity'
              onChange={(e) =>
                setItem({ ...item, severity: e.target.value as Severity })
              }
            >
              {SEVERITY.map((item) => (
                <MenuItem key={item} value={item}>
                  {item}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </div>
        <div>
          <ul>
            <li>
              <strong>Regex: </strong>
              regex to match. For example, in the URL productId(s) or words such
              as products or quote
            </li>
            <li>
              <strong>Percentage Allowed: </strong>
              amount of instances that can access. For example, if there is a
              spike in traffic and we want to limit an amount of users to the
              platform. We would start with a small amount of users allowed and
              increase from there.
            </li>
            <li>
              <strong>Severity: </strong>
              To manage the intensity of the alert to be displayed. For example,
              something very important should alert, something medium should be
              warning and something low should be info.
            </li>
          </ul>
        </div>
      </DialogContent>
      <DialogActions>
        {message.name.length > 0 && (
          <Button onClick={handleDelete} color='error'>
            Delete
          </Button>
        )}
        <div style={{ flex: '1 0 0' }} />
        <Button onClick={handleClose}>Cancel</Button>
        <Button
          onClick={handleSave}
          variant='contained'
          disabled={item.name.length === 0 || item.message.length === 0}
        >
          Save
        </Button>
      </DialogActions>
    </MuiDialog>
  );
}

export default EditMessageDialog;
