import { Container, Grid } from '@mui/material';
import FlagEnv from './Env';

const ENVS = ['dev0', 'prod0', 'prod1', 'prod2', 'prod3'];

export function Envs() {
  return (
    <Container>
      <Grid container spacing={2} sx={{ marginTop: 1 }}>
        {ENVS.map((env: string) => (
          <FlagEnv key={env} collectionName={env} />
        ))}
      </Grid>
    </Container>
  );
}

export default Envs;
