import App from 'App';
import { BrowserRouter } from 'react-router-dom';

import AuthProvider from './providers/AuthProvider';
import ThemeProvider from './providers/ThemeProvider';
import FirebaseProvider from './providers/FirebaseProvider';
import ErrorBoundary from './components/error-boundary/ErrorBoundary';

function Root() {
  return (
    <ThemeProvider>
      <ErrorBoundary>
        <BrowserRouter>
          <FirebaseProvider>
            <AuthProvider>
              <App />
            </AuthProvider>
          </FirebaseProvider>
        </BrowserRouter>
      </ErrorBoundary>
    </ThemeProvider>
  );
}

export default Root;
