import { initializeApp, FirebaseApp, FirebaseOptions } from '@firebase/app';
import { getAuth, Auth } from 'firebase/auth';
import { getFirestore, Firestore } from '@firebase/firestore';

import configuration from 'configuration';

const firebaseConfig: FirebaseOptions = {
  apiKey: configuration.firebase.key.apiKey,
  authDomain: configuration.firebase.key.authDomain,
  projectId: configuration.firebase.key.projectId,
  storageBucket: configuration.firebase.key.storageBucket,
  appId: configuration.firebase.key.appId,
  measurementId: configuration.firebase.key.measurementId,
};

export const firebase: FirebaseApp = initializeApp(firebaseConfig);
export const firestore: Firestore = getFirestore(firebase);
export const firebaseAuth: Auth = getAuth(firebase);
