import { Button } from '@mui/material';
import MuiDialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

export interface Props {
  open: boolean;
  onClose: () => void;
  onSave: (item: string) => void;
  flagName: string;
}

function DeleteFlagDialog({ onClose, open, onSave, flagName }: Props) {
  const handleClose = () => {
    onClose();
  };

  const handleSave = () => {
    onSave(flagName);
    handleClose();
  };

  return (
    <MuiDialog onClose={handleClose} open={open}>
      <DialogTitle>Delete Flag</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Are you sure you want to delete the flag?
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Cancel</Button>
        <Button onClick={handleSave}>Delete</Button>
      </DialogActions>
    </MuiDialog>
  );
}

export default DeleteFlagDialog;
