import { useState, useEffect } from 'react';
import {
  updateDoc,
  collection,
  onSnapshot,
  doc,
  setDoc,
  deleteDoc,
  query,
  where,
} from 'firebase/firestore';
import { FormGroup, Typography, Button } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';

import Loader from 'components/loader/Loading';
import { useFirebase } from 'providers/FirebaseProvider';
import { useAuth } from 'providers/AuthProvider';
import CreateFlagDialog from 'components/Dialog/CreateFlagDialog';
import { Feature } from 'types/Flag.types';
import Flag from './Flag';

interface Props {
  collectionName: string;
}

export function Features({ collectionName }: Props) {
  const [loading, setLoading] = useState(true);
  const [open, setOpen] = useState(false);
  const [flags, setFlags] = useState<Feature[]>([]);
  const [flag, setFlag] = useState<string>('');
  const { isWriter } = useAuth();
  const { store } = useFirebase();

  useEffect(() => {
    setLoading(true);
    const q = query(
      collection(store, collectionName),
      where('type', '==', 'feature')
    );
    const unsub = onSnapshot(q, (querySnapshot) => {
      let features: Feature[] = [];
      querySnapshot.forEach((doc) => {
        const flag = doc.data() as Feature;
        features.push(flag);
      });
      setFlags(features);
      setLoading(false);
    });

    return () => unsub();
  }, [store, collectionName]);

  async function updateFlag(event: React.ChangeEvent<HTMLInputElement>) {
    const { name, value } = event.target;
    const flagRef = doc(store, collectionName, name);
    const boolVal = value === 'true';
    try {
      await updateDoc(flagRef, {
        value: !boolVal,
        lastModifiedOn: new Date(),
      });
    } catch (err) {
      console.log(err);
    }
  }

  async function createFlag(flagName: string) {
    const flagRef = doc(store, collectionName, flagName);

    try {
      await setDoc(flagRef, {
        name: flagName,
        type: 'feature',
        value: false,
        created: new Date(),
      });
    } catch (err) {
      console.log(err);
    }
  }

  async function deleteFlag(flagName: string) {
    const flagRef = doc(store, collectionName, flagName);

    try {
      await deleteDoc(flagRef);
    } catch (err) {
      console.log(err);
    }
  }

  const handleOpen = (flag: string) => {
    setOpen(true);
    setFlag(flag);
  };

  const handleClose = () => setOpen(false);

  return (
    <>
      <CreateFlagDialog
        open={open}
        onClose={handleClose}
        onSave={createFlag}
        flagName={flag}
      />

      <Typography variant='h5' sx={{ margin: '1rem 0' }}>
        Feature Flags
      </Typography>
      {loading && (
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <Loader />
        </div>
      )}
      <FormGroup>
        {flags.length === 0 && (
          <div style={{ marginBottom: '1rem', textAlign: 'center' }}>
            No Flags
          </div>
        )}
        {flags.length > 0 && (
          <div style={{ marginBottom: '1rem' }}>
            {flags.map((flag: Feature) => (
              <Flag
                key={flag.name}
                flag={flag}
                updateFlag={updateFlag}
                isWriter={isWriter}
                deleteFlag={deleteFlag}
              />
            ))}
          </div>
        )}
        {isWriter && (
          <Button
            onClick={() => handleOpen('')}
            startIcon={<AddIcon />}
            disabled={!isWriter}
            variant='outlined'
          >
            Add Feature Flag
          </Button>
        )}
      </FormGroup>
    </>
  );
}

export default Features;
