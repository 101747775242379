import {
  createTheme,
  ThemeProvider as MuiThemeProvider,
} from '@mui/material/styles';
import { DefaultTheme } from '@mui/private-theming';
import { PropsWithChildren } from 'react';

interface Props {
  theme?: DefaultTheme;
}

const theme = createTheme({
  palette: {
    primary: {
      main: '#0e203e',
    },
    secondary: {
      main: '#1fc4d6',
    },
  },
});

function ThemeProvider({ children }: PropsWithChildren<Props>) {
  return <MuiThemeProvider theme={theme}>{children}</MuiThemeProvider>;
}

export default ThemeProvider;
