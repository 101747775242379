import { Route, Routes } from 'react-router';

import Envs from 'components/flags/Envs';
import Header from 'components/header/Heading';

const LandingPage = () => {
  return (
    <>
      <Header />
      <Routes>
        <Route path='*' element={<Envs />} />
      </Routes>
    </>
  );
};

export default LandingPage;
