import { Checkbox, FormControlLabel } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import IconButton from '@mui/material/IconButton';

import DeleteFlagDialog from 'components/Dialog/DeleteFlagDialog';
import { Feature } from 'types/Flag.types';
import { useState } from 'react';

interface Props {
  flag: Feature;
  updateFlag: (
    e: React.ChangeEvent<HTMLInputElement>,
    checked: boolean
  ) => void;
  deleteFlag: (flagName: string) => void;
  isWriter: boolean;
}

function Flag({ flag, updateFlag, deleteFlag, isWriter }: Props) {
  const [open, setOpen] = useState(false);
  const handleClose = () => setOpen(false);

  return (
    <>
      <DeleteFlagDialog
        open={open}
        onClose={handleClose}
        onSave={deleteFlag}
        flagName={flag.name}
      />
      <div
        key={flag.name}
        style={{ display: 'flex', justifyContent: 'space-between' }}
      >
        <FormControlLabel
          control={
            <Checkbox
              name={flag.name}
              checked={flag.value}
              value={flag.value}
              onChange={updateFlag}
              disabled={!isWriter}
            />
          }
          label={flag.name}
          key={flag.name}
        />
        {isWriter && (
          <div>
            <IconButton
              onClick={() => setOpen(true)}
              aria-label='delete'
              size='small'
              disabled={!isWriter}
            >
              <DeleteIcon fontSize='small' />
            </IconButton>
          </div>
        )}
      </div>
    </>
  );
}

export default Flag;
