import { useState } from 'react';
import { Button, TextField } from '@mui/material';
import MuiDialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

export interface Props {
  open: boolean;
  onClose: () => void;
  onSave: (item: string) => void;
  flagName: string;
}

function CreateFlagDialog({ onClose, open, onSave, flagName }: Props) {
  const [flag, setFlag] = useState(flagName);

  const handleClose = () => {
    onClose();
    setFlag('');
  };

  const handleSave = () => {
    onSave(flag);
    handleClose();
  };

  return (
    <MuiDialog onClose={handleClose} open={open}>
      <DialogTitle>Create feature flag</DialogTitle>
      <DialogContent>
        <DialogContentText>
          What is the name of the feature flag?
        </DialogContentText>
        <div style={{ marginTop: '16px' }}>
          <TextField
            label='Name'
            fullWidth
            value={flag}
            onChange={(e) => setFlag(e.target.value)}
            error={flag.length === 0}
            helperText={flag.length === 0 ? 'Please provide a name' : ''}
          />
        </div>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Cancel</Button>
        <Button onClick={handleSave} disabled={flag.length === 0}>
          Save
        </Button>
      </DialogActions>
    </MuiDialog>
  );
}

export default CreateFlagDialog;
