import { Checkbox, FormControlLabel } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import IconButton from '@mui/material/IconButton';

import { MaintenanceMessage } from 'types/Flag.types';

interface Props {
  message: MaintenanceMessage;
  updateMessage: (
    e: React.ChangeEvent<HTMLInputElement>,
    checked: boolean
  ) => void;
  editMessage: (message: MaintenanceMessage) => void;
  isWriter: boolean;
}

function Message({ message, updateMessage, editMessage, isWriter }: Props) {
  return (
    <>
      <div
        key={message.name}
        style={{ display: 'flex', justifyContent: 'space-between' }}
      >
        <FormControlLabel
          control={
            <Checkbox
              name={message.name}
              checked={message.enabled}
              value={message.enabled}
              onChange={updateMessage}
              disabled={!isWriter}
            />
          }
          label={message.name}
          key={message.name}
        />
        {isWriter && (
          <div>
            <IconButton
              onClick={() => editMessage(message)}
              aria-label='delete'
              size='small'
              disabled={!isWriter}
            >
              <EditIcon fontSize='small' />
            </IconButton>
          </div>
        )}
      </div>
    </>
  );
}

export default Message;
