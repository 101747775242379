import { Routes, Route, Navigate } from 'react-router-dom';
import { Grid } from '@mui/material';

import Loader from './components/loader/Loading';
import Login from 'pages/auth/Login';
import LandingPage from 'pages/LandingPage';
import { useAuth } from 'providers/AuthProvider';

function App() {
  const { authIsReady, user } = useAuth();

  if (!authIsReady) {
    return (
      <Grid
        container
        spacing={0}
        direction='column'
        alignItems='center'
        justifyContent='center'
        style={{ minHeight: '100vh' }}
      >
        <Grid item xs={3}>
          <Loader />
        </Grid>
      </Grid>
    );
  }

  return (
    <Routes>
      <Route path='/login' element={user ? <Navigate to='/' /> : <Login />} />
      <Route path='/logout' element={<Navigate to='/Login' />} />
      <Route
        path='*'
        element={user ? <LandingPage /> : <Navigate to='/login' />}
      />
    </Routes>
  );
}

export default App;
