import { useMemo } from 'react';
import qs from 'qs';
import { useLocation } from 'react-router-dom';

export function useQuery<T>(): T {
  const { search } = useLocation();

  return useMemo(() => {
    const parsed = qs.parse(search, { ignoreQueryPrefix: true });

    return parsed as any;
  }, [search]);
}
