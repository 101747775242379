import React from 'react';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import useScrollTrigger from '@mui/material/useScrollTrigger';
import { makeStyles } from '@mui/styles';
import { Button, Container, Theme, Typography } from '@mui/material';
import { useAuth } from 'providers/AuthProvider';
import Avatar from '@mui/material/Avatar';

interface Props {
  children: React.ReactElement;
}

function ElevationScroll(props: Props) {
  const { children } = props;
  const trigger = useScrollTrigger({
    disableHysteresis: true,
    threshold: 0,
  });

  return React.cloneElement(children, {
    elevation: trigger ? 4 : 0,
  });
}

const useStyles = makeStyles((theme: Theme) => ({
  toolbarMargin: { ...theme.mixins.toolbar },
}));

function Header() {
  const classes = useStyles();
  const { logout, user } = useAuth();

  return (
    <>
      <ElevationScroll>
        <AppBar position='fixed'>
          <Container>
            <Toolbar>
              <Typography variant='h6' component='div' sx={{ flexGrow: 1 }}>
                Feature Flags
              </Typography>

              <Button color='inherit' onClick={() => logout()}>
                Logout
              </Button>
              {user && (
                <Avatar
                  alt={user.displayName || 'Unknown'}
                  src={user.photoURL || 'Unknown'}
                />
              )}
            </Toolbar>
          </Container>
        </AppBar>
      </ElevationScroll>
      <div className={classes.toolbarMargin}></div>
    </>
  );
}

export default Header;
