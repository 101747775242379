import { Paper, Theme, Typography, Grid } from '@mui/material';
import { makeStyles } from '@mui/styles';

import Features from './Features';
import Messages from './Messages';

interface Props {
  collectionName: string;
}

const useStyles = makeStyles((theme: Theme) => ({
  paper: { padding: theme.spacing(2) },
}));

export function Env({ collectionName }: Props) {
  const classes = useStyles();

  return (
    <>
      <Grid item sx={{ marginTop: 1 }} xs={12} sm={6} md={4}>
        <Paper className={classes.paper}>
          <Typography variant='h4'>{collectionName}</Typography>
          <div style={{ margin: '1rem 0' }}>
            <Features collectionName={collectionName} />
          </div>
          <div style={{ margin: '1rem 0' }}>
            <Messages collectionName={collectionName} />
          </div>
        </Paper>
      </Grid>
    </>
  );
}

export default Env;
