import debug, { Debugger } from 'debug';

const logger = debug('falcon');

class Logger {
  public trace: Debugger;
  public info: Debugger;
  public debug: Debugger;
  public log: Debugger;
  public warn: Debugger;
  public error: Debugger;

  constructor(private name: string) {
    this.trace = this.createLevel('trace', console.trace);
    this.info = this.createLevel('info', console.info);
    this.debug = this.createLevel('debug', console.debug);
    this.log = this.createLevel('log', console.log);
    this.warn = this.createLevel('warn', console.warn);
    this.error = this.createLevel('error', console.error);
  }

  static create(name: string) {
    return new Logger(name);
  }

  createLevel(name: string, level: Function) {
    const log = logger.extend(`${name}:${this.name}`);

    log.log = level.bind(console);

    return log;
  }
}

export default Logger;
