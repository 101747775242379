import React from 'react';
import { useAuth } from '../../providers/AuthProvider';
import Logo from '../../components/icons/Logo';
import GoogleIcon from '../../components/icons/Google';
import { useQuery } from '../../utils/useQuery';

import './Login.scss';

function Login() {
  const { login } = useAuth();
  const { redirect } = useQuery();

  return (
    <div className='Login'>
      <div className='Login-header'>
        <div className='Login-header-logo'>
          <Logo />
        </div>
        <div className='Login-header-name'>FALCON</div>
      </div>
      <div className='Login-main'>
        <div className='Login-prompt'>
          <div className='Login-prompt-title'>Log in to your account</div>
          <div className='Login-prompt-description'>
            Log in via your Google account to access the app
          </div>
          <div className='Login-prompt-actions'>
            <button
              className='Login-prompt-button'
              onClick={() => login(redirect ?? '/')}
            >
              <GoogleIcon />
              Continue with Google
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Login;
